import React from 'react';

import Layout from "components/layout";
import { getWPPageByID } from "lib/api";
import RenderContent from "components/render-content";
import { REVALIDATE_TIME } from 'lib/constants';
import addBlurImage from 'lib/add-blur-image';

export default function Home({ post, meta, title, jsonld, preview }) {
  const {
    nav, footer,
    structured_data,
    structured_data: { components },
  } = post;

  return (
    <Layout post={post} preview={preview} jsonld={jsonld} meta={meta} title={title} home={true} nav={nav} footer={footer}>
      <RenderContent
        structure={structured_data.structure}
        components={components}
      />
    </Layout>
  );
}

export async function getStaticProps({ preview }) {

  let post = await getWPPageByID(58513);
  const meta = post.yoast_meta || [];
  const title = post.yoast_title || "Timely Salon Software";
  const jsonld = post.yoast_json_ld || false;

  post = await addBlurImage(post);
  return {
    props: {
      preview: preview || false,
      meta,
      title,
      post,
      jsonld,
    },
    revalidate: REVALIDATE_TIME,
  };
}
